import 'bootstrap';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2'
import './scss/vendor.scss';
import './scss/app.scss';

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

var csrftoken = getCookie('csrftoken');

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    }
  },
});

$(function () {
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('[data-prompt="delete"]').on('click', promptDelete);
  // alert('foobag');
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-danger m-2',
    cancelButton: 'btn btn-secondary m-2',
  },
  buttonsStyling: false,
});

function promptDelete(el) {
  var $this = $(this);
  var formId = $this.data('prompt-form-id');
  var type = $this.data('prompt-type');
  swalWithBootstrapButtons
    .fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this ' + type + '.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        $(`#${formId}`).append('<input type="hidden" name="delete">').trigger('submit');
      }
    });
}

window.Swal = Swal;
